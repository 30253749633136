import * as React from "react";
import { css } from "@emotion/css";

import {
  ImgCaption,
  Button,
  Page,
  Section,
  SubSection,
  ImgAlign
} from "../components";

import { threeColBlock, p3Style, p3Style_w } from "../globalstyle";

import settings from "../images/settings2.png";
import responsive from "../images/responsive.png";
import computing_cloud from "../images/computing-cloud.png";
import patient_dotter from "../images/patient_dotter.png";
import message from "../images/message.png";
import check from "../images/check.png";
import paper from "../images/paper.png";
import server_dotter from "../images/server_dotter.png";
import realtime from "../images/realtime.gif";
import EmbedVideo from "../components/EmbedVideo";

const Fonctionnalites = () => {
  return (
    <Page tabTitle="Fonctionnalités" pageTitle="Fonctionnalités">
      <Section
        color="light-gray"
        customStyle={css`
          @media screen and (max-width: 1250px) {
            flex-direction: column-reverse !important;
          }
        `}
      >
        <EmbedVideo />
        <SubSection title="Dotter EDC (Electronic Data Capture)" flex={1}>
          <p className="content">
            Notre solution logicielle permet la saisie électronique des données
            de santé dans le cadre de vos protocoles de recherche. Les données
            peuvent être saisies en direct (e-source) sur la plateforme, ou sous
            forme de CRF papier puis transcrites dans le système EDC.
          </p>
        </SubSection>
      </Section>
      <Section>
        <SubSection title="eCRF (electronic Case Report Form)" flex={4}>
          <p className="content">
            Les cahiers d’observations électroniques ou eCRF améliorent la
            qualité des données saisies lors des protocoles de recherche.{" "}
            <b>Dotter.science </b>
            vous permet d’accélérer et de simplifier la saisie, la validation et
            le traitement des données grâce aux fonctionnalités suivantes :
            <ul>
              <li>Contrôle de la cohérence des données en temps réel</li>
              <li>Nombre illimité de formulaires et de variables</li>
              <li>
                Formulaires spécifiques accessibles à tout moment (EIG,
                traitements associés, etc.)
              </li>
              <li>Ajout de fichiers liés à l’étude et imprimables</li>
              <li>
                Historique complet de la saisie et des évènements (Audit trail)
              </li>
            </ul>
          </p>
        </SubSection>
        <SubSection flex={1}>
          <ImgAlign
            src={realtime}
            alt="Contrôle en temps réel"
            imgClassName={css`
              width: 100%;
              min-width: 400px !important;
              box-shadow: 0 0 8px #c7c8c8;

              @media screen and (max-width: 1200px) {
                min-width: 300px !important;
              }

              @media screen and (max-width: 1000px) {
                min-width: 200px !important;
              }
            `}
          />
        </SubSection>
      </Section>
      <Section color="dark-blue">
        <div className={threeColBlock} style={{ marginTop: 20 }}>
          <ImgCaption
            className={p3Style}
            src={settings}
            title="Processus simplifiés"
            titleformat={p3Style_w}
          >
            <p className="content">
              Réduisez les tâches redondantes de l’investigation clinique.
            </p>
          </ImgCaption>
          <ImgCaption
            className={p3Style_w}
            src={responsive}
            title="Multi-plateforme"
            titleformat={p3Style_w}
          >
            <p className="content">
              Accédez à la plateforme à tout moment, depuis n’importe quel
              appareil.
            </p>
          </ImgCaption>
          <ImgCaption
            className={p3Style_w}
            src={computing_cloud}
            title="Serveurs certifiés"
            titleformat={p3Style_w}
          >
            <p className="content">
              Travaillez en toute conformité pour la gestion de vos essais
              cliniques.
            </p>
          </ImgCaption>
        </div>
      </Section>
      <Section column>
        <SubSection title="Un outil de conception de l’eCRF simple et ergonomique">
          <p className="content">
            Sur <b>Dotter.science</b>, le chercheur peut concevoir entièrement
            les cahiers de recueil électroniques, même complexes, à partir de
            l’interface graphique intégrée :
            <ul>
              <li>Architecture organisée en phases et étapes répétables</li>
              <li>
                Démarrage rapide avec les modèles de formulaires prédéfinis
              </li>
              <li>
                5 grands types de variables (Date/heure, Mesure simple ou
                répétée, Booléen, Choix unique ou multiple, Texte)
              </li>
              <li>Variables calculées et conversion automatique</li>
              <li>Navigation conditionnelle</li>
              <li>Tests de cohérence personnalisables</li>
            </ul>
          </p>
        </SubSection>
        <img
          src={patient_dotter}
          alt="Interface dotter.science"
          className={css`
            margin-bottom: 20px;
            width: 95vw;
            max-width: 800px;
          `}
        />
      </Section>
      <Section color="blue" column>
        <SubSection title="Une gestion efficiente des études">
          <p className="content">
            <b>Dotter.science</b> est accessible sur PC, smartphone ou tablette
            via le navigateur internet et vous permet de configurer, participer
            et collaborer sur vos études de façon simple et efficace.
          </p>
          <p className="content">
            Utilisez les outils de data management intégrés à la plateforme pour
            une gestion de projets optimisée :
            <ul>
              <li>
                Randomisation simple ou par blocs, stratification possible
              </li>
              <li>Attribution de rôles spécifiques à chaque étude</li>
              <li>Tableau d’avancement des inclusions et du suivi</li>
              <li>Queries manuelles et automatiques</li>
              <li>Export des données en format Excel ou CSV</li>
            </ul>
          </p>
        </SubSection>
      </Section>
      <Section color="blue">
        <div className={threeColBlock}>
          <div>
            <img src={message} alt="message" height="110" />
            <p className="content">
              <b>Interactions facilitées</b> entre les intervenants de l'essai
            </p>
          </div>
          <div>
            <img src={check} alt="Données de qualité" height="110" />
            <p className="content">
              <b>Données de qualité</b> grâce à la vérification temps-réel
            </p>
          </div>
          <div>
            <img src={paper} alt="Économie de papier" height="110" />
            <p className="content">
              <b>Économies de papier</b> et expérience utilisateur optimisée
            </p>
          </div>
        </div>
      </Section>
      <Section
        callToAction={
          <Button link="../tarifs/#liste-des-tarifs" color="blue">
            ACCÉDER AUX TARIFS
          </Button>
        }
      >
        <SubSection title="Sécurité et qualité" flex={4}>
          <p className="content">
            <ul>
              <li>
                Hébergement des données sur des serveurs certifiés pour les
                données de santé (HDS) et ISO 27001{" "}
              </li>
              <li>4 sauvegardes de la base de données par jour</li>
              <li>Authentification et protocoles sécurisés</li>
              <li>Conforme au RGPD</li>
            </ul>
          </p>
          <p className="content">
            <b>Dotter.science</b> vous aide à réaliser vos études dans le
            respect des Bonnes Pratiques de Recherche Clinique (BPC).
          </p>
        </SubSection>
        <SubSection flex={1}>
          <ImgAlign
            src={server_dotter}
            alt="Serveur HDS"
            imgClassName={css`
              width: 60%;
              min-width: 150px !important;
            `}
          />
        </SubSection>
      </Section>
    </Page>
  );
};

export default Fonctionnalites;
